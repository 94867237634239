import {
    emitProject,
    ProjectEmitOpts,
    ProjectEmitPriority,
} from "../project-data-coordinator"
import debounce from "lodash/debounce"
import { sendWSMessage } from "../socket-client"
import { atom } from "../utils/store"
import { initialProjectDetailsDeferred } from "../rtc-handlers"
import isString from "lodash/isString"
import { isCodeSpace } from "../space"
import JSZip from "jszip"

export interface CodeEditorSettings {
    showLineNumbers: boolean
    textSize: number
}

export enum CodeEditorFileType {
    Code,
    Asset,
    Folder,
}

export interface CodeFile {
    type: CodeEditorFileType.Code
    isText: true
    path: string
    content: string
}

export interface AssetFile {
    type: CodeEditorFileType.Asset
    isText: false
    path: string
    // Only present if being uploaded
    content: Blob
}

export interface Folder {
    type: CodeEditorFileType.Folder
    isText: false
    path: string
    children: CodeEditorFile[]
}

export type CodeEditorFile = CodeFile | AssetFile | Folder

export interface CodeEditorState {
    localVersion: number

    files: (CodeEditorFile)[]
    // Whether the preview will auto refresh when
    // editor contents are changed
    isPaused: boolean

    // Whether the preview is paused and a screenshot
    // is shown in place
    isHalted: boolean

    // Local settings - not persisted
    settings: CodeEditorSettings

    // Shared metadata about project
    manifest?: CodeEditorManifest

    refresh: number
}

export interface CodeEditorManifest {
    name: string
}

// Current editor code which component will subscribe
// to. This code can be owned by the current user or
// visited collaborator
export const state = atom<CodeEditorState>({
    files: [],
    isPaused: false,
    isHalted: false,
    localVersion: 0,
    settings: {
        showLineNumbers: true,
        textSize: 11,
    },
    refresh: 0,
})

const needsRemotePreview = () => {
    // For the most common case where we are just making small modifications
    // to sketch.js we want to skip remote preview and keep things faster
    const files = state.get().files
    if (files.length !== 3) return true
    for (const f of files) {
        if (f.path === "index.html" && f.isText) {
            if (f.content !== getDefaultHTML()) {
                return true
            }
        } else if (f.path !== "sketch.js" && f.path !== "index.css") {
            return true
        }
    }
    return false
}

const emitEntries = (
    entries: NonNullable<ProjectEmitOpts["entries"]>,
    priority: ProjectEmitPriority = "high"
) => {
    if (!initialProjectDetailsDeferred.didResolve) return
    emitProject({
        priority,
        entries,
        ensurePreview: needsRemotePreview(),
    })
}

// Updates files in state obj
// If filePath is not present, it will add a new file/folder
// This will never remove a file/folder
const updateContent = (filePath: string, content?: string | Blob) => {
    // We must traverse each segment of the path, creating folders as needed

    state.update((it) => {
        const segments = filePath.split("/")
        let currentPath = ""
        let currentFiles = it.files
        for (let i = 0; i < segments.length; i++) {
            const segment = segments[i]
            if (segment === "") continue
            currentPath += segment

            if (!segment.includes(".")) {
                currentPath += "/"
            }

            const existing = currentFiles.find((f) => f.path === currentPath)
            if (!existing) {
                // Create a new file/folder
                const isFile = i === segments.length - 1 && segment.includes(".")
                const isText = isFile && isString(content)
                let newFile: CodeEditorFile;

                if (isFile) {
                    if (isText) {
                        newFile = {
                            type: CodeEditorFileType.Code,
                            path: currentPath,
                            isText: true,
                            content: content as string,
                        }
                    } else {
                        newFile = {
                            type: CodeEditorFileType.Asset,
                            path: currentPath,
                            isText: false,
                            content: content as Blob,
                        }
                    }
                } else {
                    newFile = {
                        type: CodeEditorFileType.Folder,
                        path: currentPath,
                        isText: false,
                        children: [],
                    }
                }

                currentFiles.push(newFile)
                currentFiles = newFile.type === CodeEditorFileType.Folder ? newFile.children : currentFiles
            } else {
                if (existing.type !== CodeEditorFileType.Folder && content !== undefined && content !== null) {
                    existing.content = content;
                } else if (existing.type === CodeEditorFileType.Folder) {
                    currentFiles = existing.children
                }
            }
        }


        return {
            ...it,
            files: [...it.files],
        }

    });

    emitEntries({
        updated: [filePath],
    });
}

export const findFile = (fileName: string, files: CodeEditorFile[]): CodeEditorFile | undefined => {
    for (const file of files) {
        if (file.path === fileName) {
            return file
        }
        if (file.type === CodeEditorFileType.Folder) {
            const found = findFile(fileName, file.children)
            if (found) {
                return found
            }
        }
    }
}

interface SearchEntry {
    key: string
    content?: string | Blob
}

export const searchEntries = (paths: string[], files: CodeEditorFile[], results: SearchEntry[] = []): SearchEntry[] => {
    for (const file of files) {
        const path = file.path
        if (paths.includes(path)) {
            results.push({
                key: path,
                content: (file.type === CodeEditorFileType.Code || file.type === CodeEditorFileType.Asset) ? file.content : undefined,
            })
        }
        if (file.type === CodeEditorFileType.Folder) {
            searchEntries(paths, file.children, results)
        }
    }
    return results
}

export const recursiveDeletePath = (files: CodeEditorFile[], path: string) => {
    for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (file.path === path) {
            files.splice(i, 1)
            return true
        }
        if (file.type === CodeEditorFileType.Folder) {
            if (recursiveDeletePath(file.children, path)) {
                return true
            }
        }
    }
    return false
}

const removeContent = (filePath: string) => {

    state.update((it) => {
        const files = [...it.files];
        recursiveDeletePath(files, filePath)


        return {
            ...it,
            files,
        }
    })
    emitEntries({
        deleted: [filePath],
    })
}

const updateSettings = (
    update: (prevSettings: CodeEditorSettings) => CodeEditorSettings
) => {
    state.update((it) => ({
        ...it,
        settings: update(it.settings),
    }))
}

const updateTextSize = (textSize: number) => {
    updateSettings((it) => ({
        ...it,
        textSize,
    }))
}

const toggleLineNumbers = () => {
    updateSettings((it) => ({
        ...it,
        showLineNumbers: !it.showLineNumbers,
    }))
}

const textSizeBounds = {
    min: 5,
    max: 50,
}

const decrementTextSize = () => {
    updateSettings((it) => ({
        ...it,
        textSize: Math.max(textSizeBounds.min, it.textSize - 1),
    }))
}

const incrementTextSize = () => {
    updateSettings((it) => ({
        ...it,
        textSize: Math.min(textSizeBounds.max, it.textSize + 1),
    }))
}

const reset = () => {
    initDefault()
}

export const codeEditorStore = {
    state: state,
    updateContent,
    removeContent,
    updateSettings,
    updateTextSize,
    toggleLineNumbers,
    decrementTextSize,
    incrementTextSize,
    reset,
    needsRemotePreview,
}

export const initDefault = () => {
    state.update((it) => {
        return {
            ...it,
            files: [
                {
                    type: CodeEditorFileType.Code,
                    path: "index.html",
                    isText: true,
                    content: getDefaultHTML(),
                },
                {
                    type: CodeEditorFileType.Code,
                    path: "index.css",
                    isText: true,
                    content: getDefaultStyle(),
                },
                {
                    type: CodeEditorFileType.Code,
                    path: "sketch.js",
                    isText: true,
                    content: getDefaultJS(),
                },
            ],
            localVersion: it.localVersion + 1,
        }
    })
    emitProject()
}

const constructZip = async (zip: JSZip = new JSZip(), files: CodeEditorFile[]) => {
    for (const file of files) {
        if (file.type === CodeEditorFileType.Code) {
            zip.file(file.path, file.content, {
                binary: false,
                createFolders: true,
            })
        } else if (file.type === CodeEditorFileType.Asset) {
            zip.file(file.path, file.content, {
                binary: true,
                createFolders: true,
            })
        } else if (file.type === CodeEditorFileType.Folder) {
            if (file.children.length === 0) {
                zip.folder(file.path)
            } else {
                await constructZip(zip, file.children);
            }
        }
    }
    return zip
}

export const getCodeArchive = async () => {
    const { default: JSZip } = await import("jszip")
    const zip = await constructZip(
        new JSZip(),
        state.get().files
    );
    return {
        data: await zip.generateAsync({ type: "blob" }), assets: state.get().files.map(
            f => {
                return {
                    fileName: f.path,
                }
            }
        )
    }
}

export const contentTypeFromPath = (path: string) => {
    if (path.match(/\.js$/)) return "application/javascript"
    if (path.match(/\.html$/)) return "text/html"
    if (path.match(/\.css$/)) return "text/css"
    if (path.match(/\.png$/)) return "image/png"
    if (path.match(/\.jpg$/)) return "image/jpeg"
    if (path.match(/\.jpeg$/)) return "image/jpeg"
    if (path.match(/\.gif$/)) return "image/gif"
    if (path.match(/\.svg$/)) return "image/svg+xml"
    if (path.match(/\.mp3$/)) return "audio/mpeg"
    if (path.match(/\.wav$/)) return "audio/wav"
    if (path.match(/\.mp4$/)) return "video/mp4"
    if (path.match(/\.webm$/)) return "video/webm"


    return "application/octet-stream"

}

const loadZip = async (zip: JSZip, files: CodeEditorFile[] = []) => {
    const filesP: Promise<CodeEditorFile>[] = []
    zip.forEach((path, file) => {
        filesP.push(
            (async () => {
                if (file.dir) {
                    return {
                        type: CodeEditorFileType.Folder,
                        path,
                        isText: false,
                        children: await loadZip(zip.folder(path)!, []),
                    }
                }

                // if asset is an image, we need to convert it to a blob
                if (!path.match(/\.js$/) && !path.match(/\.html$/) && !path.match(/\.css$/)) {
                    const arrayBuffer = await file.async("arraybuffer");
                    const blob = new Blob([arrayBuffer], {
                        type: contentTypeFromPath(path),
                    });

                    return {
                        type: CodeEditorFileType.Asset,
                        path,
                        isText: false,
                        content: blob,
                    }
                }

                const content = await file.async("string")
                return {
                    type: CodeEditorFileType.Code,
                    path,
                    isText: true,
                    content,
                }
            })()
        )
    })
    const newFiles = await Promise.all(filesP)
    return files.concat(newFiles)
}

export const loadCodeArchive = async (archive: ArrayBuffer) => {
    const { default: JSZip } = await import("jszip")
    const zip = new JSZip()
    await zip.loadAsync(archive)
    const filesP: Promise<CodeEditorFile>[] = []

    // First we must create all directories, and cache folders so that
    // we can directly insert later
    const folders: Map<string, Folder> = new Map();
    const fileList: Promise<CodeEditorFile>[] = [];

    const folderPaths = new Set<string>()

    zip.forEach((path, file) => {
        if (file.dir) {
            folderPaths.add(path)
        } else {
            fileList.push(
                (async () => {
                    if (!path.match(/\.js$/) && !path.match(/\.html$/) && !path.match(/\.css$/)) {
                        const arrayBuffer = await file.async("arraybuffer");
                        const blob = new Blob([arrayBuffer], {
                            type: contentTypeFromPath(path),
                        });

                        return {
                            type: CodeEditorFileType.Asset,
                            path,
                            isText: false,
                            content: blob,
                        }
                    }

                    const content = await file.async("string")
                    return {
                        type: CodeEditorFileType.Code,
                        path,
                        isText: true,
                        content,
                    }
                })()
            )
        }
    });


    const awaitedFiles = await Promise.all(fileList);

    // sort folders by depth
    const sortedFolders = Array.from(folderPaths).sort((a, b) => a.split("/").length - b.split("/").length)

    for (const path of sortedFolders) {
        const folder: Folder = {
            type: CodeEditorFileType.Folder,
            path,
            isText: false,
            children: [],
        };
        folders.set(path, folder);

        const parentFolder = path.split("/").slice(0, -2).join("/") + "/";

        if (parentFolder && folders.has(parentFolder)) {
            folders.get(parentFolder)!.children.push(folder);
        } else {
            filesP.push(Promise.resolve(folder));
        }
    }


    // Now we can insert files into the correct folders
    awaitedFiles.forEach((file) => {
        const path = file.path;
        const parentPath = path.split("/").slice(0, -1).join("/") + "/";

        if (folders.has(parentPath)) {
            folders.get(parentPath)!.children.push(file);
        } else {
            filesP.push(Promise.resolve(file));
        }
    });


    const files = await Promise.all(filesP)
    state.update((it) => ({
        ...it,
        files,
        localVersion: it.localVersion + 1,
    }))
}

export const tidyCode = () => {
    const updated: string[] = []
    // @ts-ignore
    import("pretty-js").then(({ default: beautify }) => {
        state.update((it) => {
            try {
                return {
                    ...it,
                    files: it.files.map((f) => {
                        try {
                            if (f.path.match(/\.js$/) && f.isText) {
                                const content = beautify(f.content)
                                if (content !== f.content) {
                                    updated.push(f.path)
                                    return { ...f, content }
                                }
                            }
                        } catch (e) {
                            console.error(e)
                        }
                        return f
                    }),
                    localVersion: it.localVersion + 1,
                }
            } catch (e) {
                console.error("Failed to beautify: ", e)
                return it
            }
        })
    })
    if (updated.length > 0) {
        emitEntries({ updated }, "low")
    }
}

export const getDefaultHTML = () => {
    return `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <script>
    </script>
    <script src="p5.js"></script>

    <link rel="stylesheet" type="text/css" href="index.css">
    <script src="sketch.js"></script>
  </head>
  <body>
  </body>
</html>`
}

const getDefaultStyle = () => {
    return `
html, body {
  margin: 0;
  padding: 0;
}
canvas {
  display: block;
}`
}

const getDefaultJS = () => {
    return `
function setup() {
  // Create the canvas
  createCanvas(480, 360);

  // Set colors
  background('white');
  fill(204, 101, 192, 127);
  stroke(127, 63, 120);

  // Learn how to use CoCo signals
  // to program collaborative interactions across projects!

  // Listen for signals from all creators in the space
  coco.onSignal("hello", function (data) {
    console.log("Hello from signals with data: ", data);
  });

  // Send a signal to everyone in the space
  coco.sendSignal("hello", {"message": "Hello from signals!"});
}

function draw() {
  ${getRandomShape()}
}`
}
const shapes = [
    "rect(40, 120, 120, 40);",
    "ellipse(240, 240, 80, 80);",
    "triangle(300, 100, 320, 100, 310, 80);",
]

export const getRandomShape = () => {
    const shapeIdx = Math.round(Math.random() * (shapes.length - 1))
    return shapes[shapeIdx]
}

state
    .select((it) => it.settings)
    .subscribe(
        debounce((settings) => {
            if (isCodeSpace() && initialProjectDetailsDeferred.didResolve) {
                sendWSMessage({
                    type: "update-space-config",
                    key: "codeEditorSettings",
                    value: settings,
                })
            }
        }, 1000)
    )
